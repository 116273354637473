<template>
    <div>
        <v-row v-if="!$parent.$parent.get_permission('show_nefo')">
            <v-col>
                <AccessMessage />
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col cols="12">
                <v-btn class="ma-1" large solo depressed color="secondary" @click="$parent.$parent.reloadComponent()">
                    <v-icon class="mr-2">refresh</v-icon>
                    <span class="subtitle-2">Recargar</span>
                </v-btn>
            </v-col>
            <v-col cols="12" v-for="(item, i) in index" v-bind:key="i">
                <DashCard :title="item.nombre">
                    <v-flex class="pb-10 overflow-x-auto">
                    <v-img :src="item.image" min-height="400" min-width="1600">
                        <template v-slot:placeholder>
                             <v-row class="fill-height ma-0" align="center" justify="center">
                                <v-progress-circular indeterminate color="primary lighten-5"></v-progress-circular>
                            </v-row>
                        </template>
                    </v-img>
                    </v-flex>
                </DashCard>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { axiosHelper, messages } from '@/helpers';
import DashCard from '@/shared/components/DashCard'
import AccessMessage from '@/dash/components/AccessMessage'

export default {
    components: {
        DashCard,
        AccessMessage
    },
    data: () => ({
        index: {}
    }),
    mounted() {
        this.$emit('title', { text: "Nefobasímetros", icon: "speed" });

        if (!this.$parent.$parent.get_permission('show_nefo')) return;

        axiosHelper.get('meteodata/nefos')
            .then(response => this.index = response.data);
    }
}
</script>